<template>
	<div class="print">
		<h2><img src="@/assets/svg/Chemformation_logo_Horz.svg" height="50" /></h2>
		<template v-if="productInfo">
			<h3>Product Information</h3>
			<div id="ptabs-1" v-html="productInfo"></div>
		</template>

		<template v-if="billOfLading">
			<h3>Bill of Lading</h3>
			<div id="ptabs-2" v-html="billOfLading"></div>
		</template>

		<template v-if="featuresBenefits">
			<h3>Features &amp; Benefits</h3>
			<div id="ptabs-3" style="line-height: 0.8" v-html="featuresBenefits"></div>
		</template>

		<template v-if="productUses">
			<h3>Product Uses</h3>
			<div id="ptabs-4" v-html="productUses"></div>
		</template>
	</div>
</template>

<script>
export default {
	name: "ProductPrint",
	props: {
		vendorLogo: {
			type: String,
			default: "",
		},
		productInfo: {
			type: String,
		},
		productUses: {
			type: String,
		},
		billOfLading: {
			type: String,
		},
		featuresBenefits: {
			type: String,
		},
	},
	methods: {
		print() {
			//get Html to print
			let printHtml = document.querySelector(".print").innerHTML;

			//generate the printable html template
			const printDoc = `<!DOCTYPE html>
                <html>
                <head>
                    <style type="text/css">
                    html{height:100%}a:link{color:#003399}h2{font-size:2rem}h3{font-size:1.5rem}div.dialog-f{display:flex;box-sizing:border-box;flex-wrap:wrap;align-items:baseline;font-size:.75rem;margin:.2rem}div.dialog-fl{box-sizing:border-box;flex:1 1 15%;min-width:15%;max-width:100%}div.dialog-fv{box-sizing:border-box;flex:1 1 85%;background-color:#f0f0f0;border-color:#f0f0f0;border-width:1px;border-style:solid;border-radius:5px;padding:.25rem;width:100%;min-width:85%;max-width:100%}img.ghs{width:64px;height:64px;vertical-align:middle}span.ghs{padding-left:5px}.small-table{border-spacing:2px;border-collapse:collapse;font-size:.75rem;width:auto}.small-table th{border-bottom:1px solid #a0a0a0;font-weight:400;padding:4px;width:auto}.small-table td{padding:4px;width:auto}ul.values{list-style:none;margin:0;padding-left:0}
                    </style>
                </head>
                <body>
                    ${printHtml}
                </body>
                </html>`;

			//render new window using printDoc
			const newTab = window.open();
			newTab.document.write(printDoc);
			newTab.document.close();
			newTab.focus();
			newTab.print();
		},
	},
};
</script>
